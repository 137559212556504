<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3">
      <b-col
          lg="12"
      >
        <h1>Agregue un tratamiento</h1>
      </b-col>
      <b-col
          lg="12"
      >
        <b-form @submit.prevent="createTreatment(treatment)">
          <b-row>
            <b-col cols="6">
              <h4>Paciente: <strong>{{ name_pacient }}</strong></h4>
            </b-col>
            <b-col cols="6">
              <h4>Sala: <strong>{{ place }}</strong></h4>
            </b-col>
            <b-col cols="6">
              <h4>Inicio: <strong>{{ start }}</strong></h4>
            </b-col>
            <b-col cols="6">
              <h4>Final: <strong>{{ end }}</strong></h4>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Tratamiento"
                  label-for="Nombre"
              >
                <b-form-input
                    v-model="treatment.name"
                    placeholder="Tratamiento"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-3">
              <table>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Acciones</th>
                </tr>
                <tr v-for="(p, idx) in new_lines" :key="idx">
                  <td>
                    <v-select
                        v-model="p.product_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="product"
                    />
                  </td>
                  <td>
                    <b-form-group
                        label="Cantidad"
                        label-for="Nombre"
                    >
                      <b-form-input
                          v-model="p.qty"
                          placeholder="Cantidad"
                      />
                    </b-form-group>
                  </td>
                  <td>
                    <b-row>
                      <b-col cols="6" v-if="!p.new_line">
                        <b-button
                            @click="addRow(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar
                        </b-button>
                      </b-col>
                      <b-col cols="6" v-else>
                        <b-button
                            @click="removeLine(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Borrar
                        </b-button>
                      </b-col>
                    </b-row>


                  </td>
                </tr>
              </table>
            </b-col>


            <!-- submit and reset -->
            <b-col cols="12" class="mt-1">
              <b-button
                  style="width: 100%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
              >
                Guardar
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "CreateTreatment",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar, flatPickr, vSelect, VueAutosuggest,BOverlay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      select_product: {id: '', value: ''},
      new_lines: [
        {
          product_id: null,
          qty: 0,
          new_line: false
        }
      ],
      product: [],
      name_pacient: '',
      show: false,
      start: '',
      end: '',
      place: '',
      treatment: {
        appointment_id: null,
        name: null,
        appointee_id: null,
        charge_nurse: null,
        lines: [],

      }
    }
  },
  async created() {
    await this.searchPartner(this.$route.params.id)
    await this.findProducts()
  },
  methods: {
    ...mapActions('appointment', ['findAppointmentUserOne']),
    ...mapActions('treatment', ['findProduct','createTreatmentSend','validateCount']),

    async findProducts() {
      const response = await this.findProduct();
      for (const a of response) {
        const de = a.default_code ?  '('+a.default_code+')' : ''
        this.product.push({
          id: a.id,
          value: a.name+ ' ' + de
        })
      }
    },
    async searchPartner(id) {
      const response = await this.findAppointmentUserOne(id);
      this.treatment.appointee_id = response.ResPartner.appointee_id
      this.treatment.appointment_id = parseInt(id)
      this.name_pacient = response.ResPartner.name
      this.start = this.resHoursOdoo(response.app_dt_start)
      this.end = this.resHoursOdoo(response.app_dt_stop)
      this.place = response.appoint_place
    },
    addRow(id) {
      this.new_lines[id].new_line = true
      this.new_lines.push({
        product_id: '',
        qty: 0,
        new_line: 0,
      })
    },
    removeLine(id) {
      this.new_lines.splice(id, 1)
    },

    async createTreatment() {

      this.start = true
      let lines = []

      for (const a of this.new_lines) {
        if (a.product_id && a.qty) {
          lines.push({
            product_id: a.product_id.id,
            qty: a.qty
          })
        }
      }
      if (lines.length <= 0){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error no puede mandar lineas vacias',
            icon: 'error',
            variant: 'warning',
          },
        });
        return
      }
      this.treatment.charge_nurse = this.getUser.id
      this.treatment.lines = lines
      const response = await this.createTreatmentSend(this.treatment);
      if (response){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tratamiento creado',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.start = false

        await this.$router.push('/tratemientos');
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al crear tratamiento',
            icon: 'error',
            variant: 'warning',
          },
        });
        this.start = false
      }
    }
  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  }
}
</script>

<style lang="scss">
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
